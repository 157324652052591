var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c("section", { staticClass: "container" }, [
      _c("div", { staticClass: "page" }, [
        _c("div", { staticClass: "page__top" }, [
          _c("h1", { staticClass: "md-none" }, [
            _vm._v(_vm._s(_vm.$t("Tenant selection"))),
          ]),
        ]),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__main" }, [
      _c("div", { staticClass: "page__content" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }